.game-container {
    position: relative;
    width: 800px;
    /* Justera bredden efter din bakgrundsbild */
    height: 400px;
    /* Justera höjden efter din bakgrundsbild */
    margin: 0 auto;
}

.screen {
    background-image: url("./bgs.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: hidden;
    z-index: -10;
}


.bg-image {
    /* width: 1000px; */
    padding-top: 40px;
    height: 800px;
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: -1; */
    
    /* Lägg bakgrundsbilden bakom spelet */
}

.canvas {
    position: absolute;

    /* Justera för att passa skärmen på din bakgrundsbild */
    background-color: rgba(240, 240, 240, 1);
    /* Gör spelplanen lite genomskinlig om så önskas */
    border: 1px solid #000;
    border-radius:  10px;

}

.snake-segment {
    position: absolute;
    width: 20px;
    height: 20px;
    
    background-color: #000;
}

.food {
    position: absolute;
    width: 20px;
    height: 20px;
}

.game-over {
    text-align: center;
    font-size: 24px;
    color: red;
    margin-top: 20px;
}

.score {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 25px;
    color: black;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}

.restart-button {
    position: absolute;
    top: 80%;
    left: 48.5%;
    transform: translateX(-50%);
    font-size: 18px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.restart-button-1 {
    position: absolute;
    top: 65%;
    left: 48.5%;
    transform: translateX(-50%);
    font-size: 18px;
    padding: 10px 20px;
    background-color: #ff0202;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.restart-button:hover {
    background-color: #45a049;
}
.socials {
    position: absolute;
    bottom: 20px;
    right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 1000;
    /* Lägg till mellanrum mellan ikonerna */
}

.socials a,
.copy-button {
    font-size: 88px;
    color: #ffffff;
    text-decoration: none;
    transition: transform 0.3s ease, color 0.3s ease;
}

.socials a:hover,
.copy-button:hover {
    color: #4CAF50;
    transform: scale(1.2);
}

.copy-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    /* Lägg till mellanrum mellan ikonen och texten */
    font-size: 32px;
    /* Justera textstorleken */
}

.heading {
    text-align: center;
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 20px;
}

.copy-container {
    position: absolute;
    top: 30px;
    right: 80px;
}

.controls-info {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 14px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #000;
    z-index: 1000;
}

.corner-image {
    position: absolute;
    top: 100px;
    left: 250px;
    width: 250px;
    /* Justera storleken efter behov */
    height: auto;
    z-index: 1000;
}

.corner-image-2 {
    position: absolute;
    top: 10px;
    left: 830px;
    width: 250px;
    /* Justera storleken efter behov */
    height: auto;
    z-index: 1000;
}

.corner-image-3 {
    position: absolute;
    top: 100px;
    right: 250px;
    width: 250px;
    /* Justera storleken efter behov */
    height: auto;
    z-index: 1000;
    
}